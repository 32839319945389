<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  viewBox="0 0 512.001 512.001"
  width="100%"
  class={$$props.class || ""}
>
  <path
    style="fill:#E35336;"
    d="M248.46,21.973c2.044-10.255-5.204-20.055-15.611-21.066c-21.501-2.091-43.718-0.612-65.876,4.914
	s-42.467,14.653-60.468,26.595c-8.714,5.781-10.511,17.836-3.891,25.93l119.489,188.151c9.634,15.171,33.131,7.969,32.609-9.997
	L248.46,21.973z"
  />
  <path
    style="fill:#D93C1C;"
    d="M233.876,237.654L114.386,49.503c-5.281-6.458-5.19-15.428-0.518-21.729
	c-2.494,1.497-4.951,3.043-7.363,4.643c-8.714,5.781-10.511,17.836-3.891,25.93l119.489,188.151
	c7.821,12.315,24.775,9.886,30.601-1.072C245.987,246.969,238.401,244.782,233.876,237.654z"
  />
  <path
    style="fill:#E35336;"
    d="M96.373,222.69c-9.122-5.113-20.682-1.248-24.86,8.338c-8.633,19.803-14.091,41.389-15.683,64.17
	c-1.592,22.781,0.812,44.917,6.607,65.727c2.805,10.073,13.715,15.508,23.458,11.714l129.108-50.288
	c13.923-5.423,15.264-24.6,2.23-31.908L96.373,222.69z"
  />
  <path
    style="fill:#D93C1C;"
    d="M79.034,360.925c-5.795-20.811-8.199-42.946-6.607-65.727c1.592-22.782,7.05-44.368,15.683-64.17
	c1.667-3.822,4.517-6.716,7.908-8.512c-9.064-4.822-20.379-0.95-24.504,8.512c-8.633,19.803-14.091,41.389-15.683,64.17
	c-1.592,22.781,0.812,44.917,6.607,65.727c2.805,10.073,13.715,15.508,23.458,11.714l2.028-0.79
	C83.741,369.68,80.41,365.866,79.034,360.925z"
  />
  <path
    style="fill:#E35336;"
    d="M126.114,436.992c-7.681,7.094-7.577,19.283,0.248,26.22c16.166,14.329,35.008,26.192,56.183,34.745
	s42.969,13.108,64.552,14.027c10.447,0.445,18.988-8.252,18.389-18.691l-7.93-138.329c-0.856-14.918-18.68-22.119-29.657-11.98
	L126.114,436.992z"
  />
  <path
    style="fill:#D93C1C;"
    d="M256.916,495.191c-21.582-0.92-43.378-5.474-64.552-14.027
	c-21.175-8.553-40.017-20.415-56.183-34.745c-3.966-3.516-5.94-8.381-5.93-13.249l-4.136,3.821
	c-7.681,7.094-7.577,19.283,0.248,26.22c16.166,14.329,35.008,26.191,56.183,34.745c21.175,8.553,42.969,13.108,64.552,14.026
	c10.423,0.444,18.944-8.212,18.39-18.619C262.917,494.64,260.005,495.323,256.916,495.191z"
  />
  <path
    style="fill:#E35336;"
    d="M339.119,474.929c4.374,9.498,15.998,13.166,25.012,7.867c18.623-10.947,35.728-25.201,50.406-42.696
	s25.744-36.816,33.288-57.058c3.651-9.798-1.98-20.609-12.094-23.265l-134.009-35.204c-14.453-3.797-26.809,10.93-20.558,24.503
	L339.119,474.929z"
  />
  <path
    style="fill:#D93C1C;"
    d="M354.594,469.402l-57.955-125.854c-3.259-7.077-1.452-14.462,3.065-19.377
	c-13.552-2.002-24.497,11.969-18.54,24.904l57.954,125.854c4.374,9.498,15.998,13.166,25.012,7.867
	c1.739-1.022,3.459-2.083,5.171-3.161C363.154,479.149,357.39,475.476,354.594,469.402z"
  />
  <path
    style="fill:#E35336;"
    d="M441.021,284.073c10.385-1.225,17.465-11.147,15.211-21.357
	c-4.656-21.095-12.927-41.767-25.03-61.133s-27.059-35.861-43.978-49.291c-8.191-6.501-20.212-4.486-25.864,4.312l-74.892,116.572
	c-8.077,12.571,2.111,28.874,16.951,27.124L441.021,284.073z"
  />
  <path
    style="fill:#D93C1C;"
    d="M454.824,256.825c-2.549,5.182-7.571,9.059-13.803,9.794l-137.603,16.227
	c-7.709,0.909-14.154-3.057-17.444-8.837c-7.124,12.447,2.929,28.004,17.444,26.291l137.603-16.227
	c10.385-1.225,17.465-11.147,15.211-21.357C455.798,260.748,455.321,258.785,454.824,256.825z"
  />
</svg>
