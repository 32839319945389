<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  viewBox="0 -31.5 256 256"
  width="100%"
  class={$$props.class || ""}
>
  <g>
    <path
      d="M58.1818182,192.049515 L58.1818182,93.1404244 L27.5066233,65.0770089 L0,49.5040608 L0,174.59497 C0,184.253152 7.82545455,192.049515 17.4545455,192.049515 L58.1818182,192.049515 Z"
      fill="#4285F4"
    >
    </path>
    <path
      d="M197.818182,192.049515 L238.545455,192.049515 C248.203636,192.049515 256,184.224061 256,174.59497 L256,49.5040608 L224.844415,67.3422767 L197.818182,93.1404244 L197.818182,192.049515 Z"
      fill="#34A853"
    >
    </path>
    <polygon
      fill="#EA4335"
      points="58.1818182 93.1404244 54.0077618 54.4932827 58.1818182 17.5040608 128 69.8676972 197.818182 17.5040608 202.487488 52.4960089 197.818182 93.1404244 128 145.504061"
    >
    </polygon>
    <path
      d="M197.818182,17.5040608 L197.818182,93.1404244 L256,49.5040608 L256,26.2313335 C256,4.64587897 231.36,-7.65957557 214.109091,5.28587897 L197.818182,17.5040608 Z"
      fill="#FBBC04"
    >
    </path>
    <path
      d="M0,49.5040608 L26.7588051,69.5731646 L58.1818182,93.1404244 L58.1818182,17.5040608 L41.8909091,5.28587897 C24.6109091,-7.65957557 0,4.64587897 0,26.2313335 L0,49.5040608 Z"
      fill="#C5221F"
    >
    </path>
  </g>
</svg>
