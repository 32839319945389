<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  viewBox="0 0 256 282"
  fill="currentColor"
  width="100%"
  class={$$props.class || ""}
>
  <defs>
    <clipPath id="logo">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M179.213 263.197C179.213 252.343 185.792 242.743 195.034 237.034L195.008 237.059C218.76 222.465 237.099 200.516 247.237 174.547C257.374 148.578 258.757 120.01 251.174 93.1833C243.591 66.3568 227.457 42.7394 205.225 25.9203C182.993 9.10116 155.878 0 128 0C100.122 0 73.0071 9.10116 50.7748 25.9203C28.5426 42.7394 12.409 66.3568 4.82619 93.1833C-2.75666 120.01 -1.3742 148.578 8.76345 174.547C18.9011 200.516 37.2397 222.465 60.992 237.059C70.2336 242.743 76.8128 252.343 76.8128 263.197C76.7682 266.01 77.6446 268.761 79.3083 271.031C80.9722 273.3 83.3322 274.963 86.0288 275.767C99.3408 279.53 113.446 281.578 128.013 281.578C142.21 281.58 156.339 279.616 169.997 275.741C175.578 274.205 179.213 268.983 179.213 263.197ZM128.414 230C81.9248 230 43.16 196.957 34.3088 153.072C32.2224 142.691 41.0096 134 51.6144 134H205.214C215.819 134 224.613 142.685 222.52 153.072C213.662 196.963 174.904 230 128.414 230Z"
      />
    </clipPath>
  </defs>
  <foreignObject clip-path="url(#logo)" width="100%" height="100%">
    <div class="bg-primary-gradient h-full w-full"></div>
  </foreignObject>
</svg>
